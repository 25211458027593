<template lang="pug">
#spr-experimental
  h3.mb-6 {{ $t('SPR.title') }}
  .spr-wrapper
    .spr-add-wrapper
      .title {{ $t('SPR.new.title') }}
      add
    .spr-table-wrapper.mt-3
      list
</template>
<script>
  export default {
    components: {
      add: () => import('@/components/SPR/SPRConfigAdd.vue'),
      list: () => import('@/components/SPR/SPRConfigTable.vue'),
    },
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>

<style lang="sass">
  .spr-add-wrapper
    .title
      font-size: 90%
</style>
