<template lang="pug">
.product-page-optimizer
  h3.mb-6 {{ $t('productPageOptimizer.heading') }}
  .ppo-wrapper
    .ppo-domain-wrapper(v-for="(domain, index) in compatibleDomains" :key="index")
      h3.mb-3 {{ domain.domain }}
      .top-actions-wrapper
        .d-flex.w-100
          .left-actions.me-auto.w-100.d-flex
            om-kebab-menu.template-dropdown
              template(slot="reference")
                om-button.mr-2(
                  iconOnly
                  primary
                  icon="plus-circle"
                  :data-testid="`addPromptButton-${domain.domain}`"
                )
              template(slot="custom")
                .kebab-option(
                  v-for="(template, key) in ppoPromptTemplates"
                  :key="key"
                  @click="openUpsertPromptModal(domain, template)"
                ) {{ template.label }}
            span(v-if="hasPromptWithProductData(domain)")
              om-button.mr-2(secondary icon="file-download" @click="exportTopSample(domain)") {{ $t('productPageOptimizer.export100') }}
            span(v-if="hasPromptWithExternalData(domain)")
              om-button.mr-2(
                secondary
                icon="file-download"
                @click="exportTopSample(domain, { useExternalData: true })"
              ) {{ $t('productPageOptimizer.exportexternal100') }}
            om-tooltip(transition="fade" v-if="hasPromptWithProductData(domain)")
              div {{ $t('productPageOptimizer.exportResults') }}
              div {{ $t(hasFinishedGenerationForProducts(domain) ? 'productPageOptimizer.exportResultsTooltip' : 'productPageOptimizer.exportResultsDisabledTooltip') }}
              template(slot="trigger")
                om-button.mr-2(
                  :disabled="!hasFinishedGenerationForProducts(domain)"
                  secondary
                  icon="file-download"
                  @click="showExportResultsModal(domain)"
                  icon-only
                )
            om-tooltip(transition="fade" v-if="hasPromptWithExternalData(domain)")
              div {{ $t('productPageOptimizer.exportExternalResults') }}
              div {{ $t(hasFinishedGenerationForPages(domain) ? 'productPageOptimizer.exportResultsTooltip' : 'productPageOptimizer.exportResultsDisabledTooltip') }}
              template(slot="trigger")
                om-button.mr-2(
                  :disabled="!hasFinishedGenerationForPages(domain)"
                  secondary
                  icon="file-download"
                  @click="showExportResultsModal(domain, { useExternalData: true })"
                ) {{ $t('productPageOptimizer.exportExternalResults') }}
            input(
              ref="csvInput"
              type="file"
              style="display: none"
              accept=".csv"
              @input="handleFileSelect(index, domain._id)"
              :disabled="isCSVImportDisabled(domain)"
            )
            om-tooltip(transition="fade")
              span {{ $t('productPageOptimizer.CSVImport.importCSV') }}
              template(slot="trigger")
                om-button.mr-2(
                  secondary
                  icon="upload"
                  @click.prevent="clickFileUpload(index)"
                  :disabled="isCSVImportDisabled(domain)"
                  icon-only
                )
          .right-actions.d-flex.align-items-center
            om-body-text.d-flex.align-items-center.justify-content-end.mr-2(
              v-if="hasPublishError(domain)"
              bt400md
            )
              span {{ $t('productPageOptimizer.publishError') }}
            om-body-text.d-flex.flex-column.align-items-start.justify-content-center.mr-2(
              bt400md
              v-else
            )
              span.unpublished-changes(v-if="hasUnpublishedChanges(domain)") {{ $t('productPageOptimizer.hasUnpublishedChanges') }}
              span.last-successful-publish(v-if="getLastSuccessfulPublish(domain)") {{ $t('productPageOptimizer.lastSuccessfulPublish') }} {{ getLastSuccessfulPublish(domain) }}
            om-tooltip(transition="fade")
              span {{ $t('productPageOptimizer.publish') }}
              template(slot="trigger")
                om-button(
                  primary
                  icon="cloud-upload"
                  :disabled="isPublishActionDisabled(domain)"
                  @click="publishPPOPrompt(domain._id)"
                  icon-only
                  :data-testid="`publishButton-${domain.domain}`"
                )
      .table.brand-table.overflow-y-auto.col.px-0.table-column-secondary.mt-6
        .thead.scroll.mb-0
          .tr
            .th(
              scope="col"
              v-for="(column, index) in columns"
              :class="[`ppo-table-col-${column.key}`, `brand-table-th-${index}`]"
            )
              span.table-column-header.d-flex.pb-0.justify-content-start
                span.title.d-flex.cursor-pointer.m-3.pb-0 {{ column.header }}

        .tbody
          template(v-for="(prompt, index) in getPPOPromptsByDomain(domain)")
            .tr.brand-table-tr.brand-table-tr-header(:key="index")
              .td.brand-table-td.ppo-table-row-name.brand-table-td-0.text-left.m-3
                div {{ prompt.displayName }}
                .brand-under-heading-text {{ prompt.variableNames.join(', ') }}
              .td.brand-table-td.ppo-table-row-name.brand-table-td-0.text-left.m-3
                .block(
                  :class="{ 'text-danger': isPromptErrored(prompt) }"
                  :data-testid="getTestIdValueForPrompt('promptStatus', prompt)"
                ) {{ getPPOPromptStatus(prompt) }}
                .block {{ getPPOPromptStatusDate(prompt) }}
              .td.brand-table-td.ppo-table-row-name.brand-table-td-0.text-left.m-3
                .d-flex.flex-row.justify-content-start
                  om-button.mr-2(
                    primary
                    small
                    icon="bolt-alt"
                    :disabled="isGenerationActionDisabled(prompt)"
                    @click="showGenerationModal(domain, prompt)"
                    icon-only
                    :data-testid="getTestIdValueForPrompt('generateButton', prompt)"
                  )
                  om-button.mr-2(
                    secondary
                    small
                    icon="edit-alt"
                    @click="openUpsertPromptModal(domain, prompt)"
                    :data-testid="getTestIdValueForPrompt('editButton', prompt)"
                    icon-only
                  )
                  om-button.mr-2(
                    secondary
                    small
                    icon="copy"
                    @click="openUpsertPromptModal(domain, getDuplicateFromPrompt(prompt))"
                    :data-testid="getTestIdValueForPrompt('cloneButton', prompt)"
                    icon-only
                  )
                  om-button.mr-2(
                    secondary
                    small
                    icon="setting"
                    @click="openAutoGenerateModal(domain, prompt)"
                    :data-testid="getTestIdValueForPrompt('autoGenerateButton', prompt)"
                    icon-only
                  )
                  om-button.mr-2(
                    secondary
                    small
                    icon="trash-alt"
                    @click="showDeleteModal(prompt)"
                    :data-testid="getTestIdValueForPrompt('deleteButton', prompt)"
                    icon-only
                  )
    upsert-p-p-o-prompt(@upsert="onPPOUpsert")
    p-p-o-confirmation-modal(@sure="fireAction($event)")
    p-p-o-auto-generate-modal(@update="onPPOUpsert")
</template>
<script>
  import UpsertPPOPrompt from '@/components/Modals/UpsertPPOPrompt';
  import { mapGetters } from 'vuex';
  import dateFormat from '@/mixins/dateFormat';
  import GET_ALL_PPO_PROMPTS from '@/graphql/GetAllPPOPrompts.gql';
  import GET_ALL_PPO_PROMPT_TEMPLATES from '@/graphql/GetAllPPOPromptTemplates.gql';
  import DELETE_PPO_PROMPT from '@/graphql/DeletePPOPrompt.gql';
  import EXPORT_TOP_SAMPLE from '@/graphql/ExportTopSample.gql';
  import GENERATE_PPO_PROMPT from '@/graphql/GeneratePPOPrompt.gql';
  import PUBLISH_PPO_PROMPT from '@/graphql/PublishPPOPrompt.gql';
  import { saveAs } from 'file-saver/FileSaver';
  import PPOConfirmationModal from '@/components/Modals/PPOConfirmationModal';
  import PPOAutoGenerateModal from '@/components/Modals/PPOAutoGenerateModal';
  import { baseUrl } from '@/config/url';
  import moment from 'moment';
  import { blank as blankSPPOTemplate } from '@/config/sppoPromptTemplates';

  export default {
    components: {
      UpsertPPOPrompt,
      PPOConfirmationModal,
      PPOAutoGenerateModal,
    },
    mixins: [dateFormat],
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    data() {
      return {
        columns: [
          { header: this.$t('productPageOptimizer.columns.name'), key: 'variable-name' },
          { header: this.$t('productPageOptimizer.columns.status'), key: 'status' },
          { header: this.$t('productPageOptimizer.columns.actions'), key: 'actions' },
        ],
        ppoPrompts: [],
        statusMap: {
          created: 'created',
          queued: 'queued',
          inProgress: 'inProgress',
          finished: 'finished',
          timeout: 'timeout',
          failed: 'failed',
        },
        ppoPromptTemplates: [],
      };
    },
    computed: {
      ...mapGetters(['domains', 'shopSettingsByDomain']),
      compatibleDomains() {
        const availableDomains = this.domains.filter(({ domain }) => domain !== '*');
        return availableDomains.map((domain) => {
          return {
            ...domain,
            shop: this.shopSettingsByDomain(domain.domain),
          };
        });
      },
      promptsByDomainId() {
        const prompts = {};

        for (const prompt of this.ppoPrompts) {
          if (!prompts[prompt.domainId]) {
            prompts[prompt.domainId] = [];
          }
          prompts[prompt.domainId].push(prompt);
        }

        return prompts;
      },
    },
    async mounted() {
      [this.ppoPrompts, this.ppoPromptTemplates] = await Promise.all([
        this.getAllPPOPrompts(),
        this.getAllPPOPromptTemplates(),
      ]);
      this.ppoPromptTemplates.unshift(blankSPPOTemplate);
    },
    methods: {
      getTestIdValueForPrompt(label, prompt) {
        return `${label}-${prompt.variableNames.join(',')}`;
      },
      hasPromptWithProductData(domain) {
        const prompts = this.getPPOPromptsByDomain(domain);
        return prompts.some((prompt) => !prompt.useExternalData);
      },
      hasPromptWithExternalData(domain) {
        const prompts = this.getPPOPromptsByDomain(domain);
        return prompts.some((prompt) => prompt.useExternalData);
      },
      isCSVImportDisabled(domain) {
        const hasAnyFinishedGeneration =
          this.hasFinishedGenerationForProducts(domain) ||
          this.hasFinishedGenerationForPages(domain);

        return !hasAnyFinishedGeneration || this.hasGenerationInProgress(domain);
      },
      hasGenerationInProgress(domain) {
        const prompts = this.getPPOPromptsByDomain(domain);
        return prompts.some((prompt) => this.isGenerationActionDisabled(prompt));
      },
      hasFinishedGenerationForProducts(domain) {
        const prompts = this.getPPOPromptsByDomain(domain);

        return prompts.some(
          (prompt) => !!prompt.lastGeneration?.finishedAt && !prompt.useExternalData,
        );
      },
      hasFinishedGenerationForPages(domain) {
        const prompts = this.getPPOPromptsByDomain(domain);

        return prompts.some(
          (prompt) => !!prompt.lastGeneration?.finishedAt && prompt.useExternalData,
        );
      },
      isGenerationActionDisabled(prompt) {
        return (
          prompt.lastGeneration?.uuid &&
          prompt.lastGeneration.startedAt &&
          !prompt.lastGeneration?.finishedAt
        );
      },
      isPublishActionDisabled(domain) {
        const prompts = this.getPPOPromptsByDomain(domain);

        return prompts.some((prompt) => !!this.isGenerationActionDisabled(prompt));
      },
      hasPublishError(domain) {
        const prompts = this.getPPOPromptsByDomain(domain);
        return (
          !this.isPublishActionDisabled(domain) &&
          prompts.some(
            (prompt) => prompt.published?.finishedAt && prompt.published?.error?.code !== null,
          )
        );
      },
      hasUnpublishedChanges(domain) {
        const prompts = this.getPPOPromptsByDomain(domain);
        return (
          !this.isPublishActionDisabled(domain) &&
          prompts.some((prompt) => {
            return (
              prompt.lastGeneration.finishedAt &&
              prompt.lastGeneration.error.code === null &&
              prompt.published?.error.code === null &&
              new Date(prompt.published?.startedAt) < new Date(prompt.lastGeneration?.finishedAt) &&
              (!prompt.published?.finishedAt ||
                new Date(prompt.published?.finishedAt) <
                  new Date(prompt.lastGeneration?.finishedAt))
            );
          })
        );
      },
      getPPOPromptsByDomain(domainObject) {
        return this.promptsByDomainId[domainObject._id] || [];
      },
      async onPPOUpsert() {
        this.ppoPrompts = await this.getAllPPOPrompts();
      },

      openUpsertPromptModal(domain, prompt) {
        this.$modal.show('upsert-prompt-modal', {
          domainId: domain._id,
          externalDataOnly: !domain.shop,
          prompt,
        });
      },
      openAutoGenerateModal(domain, prompt) {
        this.$modal.show('ppo-auto-generate-modal', {
          domainId: domain._id,
          prompt,
        });
      },
      isPromptErrored(prompt) {
        const { lastGeneration } = prompt;
        return !!lastGeneration.error?.code;
      },
      isTimeoutError(prompt) {
        const { lastGeneration } = prompt;

        return lastGeneration.error?.code === 'GENERATION_TIMEOUT';
      },
      determinePromptStatus(prompt) {
        if (!prompt.lastGeneration.startedAt && !prompt.lastGeneration.finishedAt) {
          return this.statusMap.created;
        }

        const { lastGeneration } = prompt;
        if (this.isTimeoutError(prompt)) {
          return this.statusMap.timeout;
        }
        if (lastGeneration.error.code !== null) {
          return this.statusMap.failed;
        }
        if (lastGeneration.startedAt && lastGeneration.finishedAt) {
          return this.statusMap.finished;
        }
        if (lastGeneration.startedAt && lastGeneration.processedProductCount === 0) {
          return this.statusMap.queued;
        }
        if (lastGeneration.startedAt && lastGeneration.processedProductCount > 0) {
          return this.statusMap.inProgress;
        }
      },
      getPPOPromptStatus(prompt) {
        const status = this.determinePromptStatus(prompt);
        if (
          [
            this.statusMap.finished,
            this.statusMap.queued,
            this.statusMap.inProgress,
            this.statusMap.timeout,
            this.statusMap.failed,
          ].includes(status)
        ) {
          const {
            lastGeneration: {
              processedProductCount,
              originalProcessedProductCount,
              loadedProductCount,
              erroredProductCount,
              error,
            },
          } = prompt;
          const totalLoadedProductCount = loadedProductCount + originalProcessedProductCount;
          const totalProcessedProductCount = processedProductCount + originalProcessedProductCount;
          let progressString = `(${totalProcessedProductCount}/${totalLoadedProductCount})`;
          if (erroredProductCount > 0) {
            progressString = `(${totalProcessedProductCount}/${totalLoadedProductCount}, ${this.$t(
              `productPageOptimizer.status.errorsCount`,
              { count: erroredProductCount },
            )})`;
          }
          if (status === this.statusMap.failed && error?.code) {
            progressString = `(${error.code})`;
          }
          return `${this.$t(`productPageOptimizer.status.${status}`)} ${progressString}`;
        }

        return this.$t(`productPageOptimizer.status.${status}`);
      },
      getPPOPromptStatusDate(prompt) {
        const { lastGeneration } = prompt;

        if (!lastGeneration || !lastGeneration?.startedAt) return '';

        if (lastGeneration?.originalProcessedProductCount >= 0) {
          return this.$t(`productPageOptimizer.date.at`, {
            date: this.formatDate(lastGeneration.startedAt, 'LLL'),
          });
        }

        return this.$t(`productPageOptimizer.date.at`, {
          date: this.formatDate(lastGeneration.finishedAt, 'LLL'),
        });
      },
      async getAllPPOPrompts() {
        const { data } = await this.$apollo.query({
          query: GET_ALL_PPO_PROMPTS,
        });

        return data?.getAllPPOPrompts || [];
      },
      async getAllPPOPromptTemplates() {
        const { data } = await this.$apollo.query({
          query: GET_ALL_PPO_PROMPT_TEMPLATES,
        });

        return data?.getAllPPOPromptTemplates || [];
      },
      async exportTopSample(domain, { useExternalData = false } = {}) {
        const { data } = await this.$apollo.query({
          query: EXPORT_TOP_SAMPLE,
          variables: {
            domainId: domain._id,
            useExternalData,
          },
        });

        const file = new File(
          [data.exportTopSample],
          `top-100${useExternalData ? '-external' : ''}-sample.csv`,
          {
            type: 'text/csv;charset=utf-8',
          },
        );
        saveAs(file);
      },
      exportResults(domainId, { limit, useExternalData } = {}) {
        const exportUrl = new URL(`/api/export/ppo-results/${domainId}`, baseUrl);
        if (limit) {
          exportUrl.searchParams.append('limit', limit);
        }

        if (useExternalData) {
          exportUrl.searchParams.append('useExternalData', useExternalData);
        }

        window.open(exportUrl, '_blank');
      },
      async fireAction(params) {
        if (params.action === 'generate') {
          await this.generatePPOPrompt({
            domainId: params.domainId,
            promptId: params.prompt._id,
            requestedProductAmount: params.amount,
            generationMode: params.generationMode,
          });
        } else if (params.action === 'publish') {
          await this.publishPPOPrompt(params.domainId);
        } else if (params.action === 'export-results') {
          this.exportResults(params.domainId, {
            limit: params.amount,
            useExternalData: params.useExternalData,
          });
        } else if (params.action === 'delete') {
          await this.deletePPOPrompt(params.prompt._id);
        }
      },
      showGenerationModal(domain, prompt) {
        this.$modal.show('ppo-confirmation-modal', {
          domainId: domain._id,
          prompt,
          action: 'generate',
        });
      },
      showPublishModal(domainId) {
        this.$modal.show('ppo-confirmation-modal', { domainId, action: 'publish' });
      },
      showExportResultsModal(domain, { useExternalData = false } = {}) {
        let generatedPrompts = this.getPPOPromptsByDomain(domain).filter(
          (prompt) => prompt.lastGeneration?.finishedAt,
        );

        if (useExternalData) {
          generatedPrompts = generatedPrompts.filter((prompt) => prompt.useExternalData);
        } else {
          generatedPrompts = generatedPrompts.filter((prompt) => !prompt.useExternalData);
        }

        this.$modal.show('ppo-confirmation-modal', {
          domainId: domain._id,
          prompts: generatedPrompts,
          action: 'export-results',
        });
      },
      async generatePPOPrompt({ domainId, promptId, requestedProductAmount, generationMode }) {
        const { data } = await this.$apollo.mutate({
          mutation: GENERATE_PPO_PROMPT,
          variables: {
            domainId,
            promptIds: [promptId],
            settings: {
              requestedProductAmount,
              generationMode,
              priority: requestedProductAmount && requestedProductAmount <= 100 ? 'HIGH' : 'NORMAL',
            },
          },
        });

        if (data?.generatePPOPrompt?.success) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.generationStarted'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.generationNotStarted'),
          });
        }
        this.ppoPrompts = await this.getAllPPOPrompts();
      },
      async publishPPOPrompt(domainId) {
        const { data } = await this.$apollo.mutate({
          mutation: PUBLISH_PPO_PROMPT,
          variables: {
            domainId,
          },
        });

        if (data?.publishPPOPrompt?.success) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.publishStarted'),
          });

          this.ppoPrompts = this.ppoPrompts
            .filter((prompt) => prompt.domainId === domainId)
            .map((promptByShop) => {
              return {
                ...promptByShop,
                published: {
                  ...promptByShop.published,
                  startedAt: new Date().toDateString(),
                  finishedAt: null,
                  error: null,
                },
              };
            });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.publishNotStarted'),
          });
        }
      },
      showDeleteModal(prompt) {
        this.$modal.show('ppo-confirmation-modal', { prompt, action: 'delete' });
      },
      async deletePPOPrompt(id) {
        const { data } = await this.$apollo.query({
          query: DELETE_PPO_PROMPT,
          variables: {
            id,
          },
        });

        if (data?.deletePPOPrompt?.success) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.deleteSuccess'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.deleteError'),
          });
        }
        this.ppoPrompts = await this.getAllPPOPrompts();
      },
      getExtensionFromStr(str) {
        return str.substring(str.lastIndexOf('.') + 1);
      },
      clickFileUpload(index) {
        this.$refs.csvInput[index].click();
      },
      async handleFileSelect(index, domainId) {
        const input = this.$refs.csvInput[index];
        const file = input.files[0];
        if (file) {
          const extension = this.getExtensionFromStr(file.name);
          if (extension !== 'csv') {
            this.$notify({
              type: 'error',
              title: this.$t(`productPageOptimizer.CSVImport.invalidFileFormatError`),
            });
            return;
          }

          const formData = new FormData();
          formData.append('file', file);

          try {
            await this.$axios.post(`import/sppo-csv/${domainId}`, formData, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            });

            this.$notify({
              type: 'success',
              title: this.$t(`productPageOptimizer.CSVImport.successfulCSVImport`),
            });
          } catch (e) {
            if (e.response.data === 'invalid csv file') {
              this.$notify({
                type: 'error',
                title: this.$t(`productPageOptimizer.CSVImport.invalidCSVFile`),
              });
            } else {
              this.$notify({
                type: 'error',
                title: this.$t(`productPageOptimizer.CSVImport.csvImportError`),
              });
            }
          }
          input.value = '';
        }
      },
      getLastSuccessfulPublish(domain) {
        const prompts = this.getPPOPromptsByDomain(domain);
        const finishedAt = prompts[0]?.published?.finishedAt;

        return finishedAt ? moment(finishedAt).format('YYYY-MM-DD HH:mm:ss') : '';
      },
      getDuplicateFromPrompt(originalPrompt) {
        const { prompt, variableNames, domainId, modelVersion, settings } = originalPrompt;

        const copiedSettings = JSON.parse(JSON.stringify(settings));
        copiedSettings.postProcess = copiedSettings.postProcess.map((setting) => {
          return {
            ...setting,
            variableName: `copy_${setting.variableName}`,
          };
        });
        copiedSettings.dataValidation = copiedSettings.dataValidation.map((setting) => {
          return {
            ...setting,
            variableName: `copy_${setting.variableName}`,
          };
        });

        return {
          prompt,
          domainId,
          modelVersion,
          settings: copiedSettings,
          variableNames: variableNames.map((variableName) => `copy_${variableName}`),
          displayName: '',
        };
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../sass/variables/_colors.sass'

  .product-page-optimizer
    .ppo-table-col-variable-name
      span
        justify-content: flex-start!important
    .unpublished-changes
      min-width: 200px
    .last-successful-publish
      min-width: 340px
</style>

<style lang="sass">
  .product-page-optimizer
    .top-actions-wrapper
      .template-dropdown
        .popper
          white-space: nowrap
          min-width: 300px
      .kebab-options-wrapper
        max-height: 400px
        overflow-y: auto
        .kebab-option
          max-width: 100%
</style>
