import SET_DOMAIN_CONTEXT from '@/graphql/SetDomainContext.gql';
import GENERATE_DOMAIN_CONTEXT from '@/graphql/GenerateDomainContext.gql';

export default {
  methods: {
    async generateDomainContext(domainId) {
      const response = await this.$apollo.mutate({
        mutation: GENERATE_DOMAIN_CONTEXT,
        variables: {
          domainId,
        },
      });

      return response;
    },

    async setDomainContext(domainId, context, siteContent) {
      const response = await this.$apollo.mutate({
        mutation: SET_DOMAIN_CONTEXT,
        variables: {
          domainId,
          context,
          siteContent,
        },
      });

      return response;
    },
  },
};
