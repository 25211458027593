import { render, staticRenderFns } from "./CampaignsAbTest.vue?vue&type=template&id=6dc6527e&scoped=true&lang=pug&"
import script from "./CampaignsAbTest.vue?vue&type=script&lang=js&"
export * from "./CampaignsAbTest.vue?vue&type=script&lang=js&"
import style0 from "./CampaignsAbTest.vue?vue&type=style&index=0&id=6dc6527e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc6527e",
  null
  
)

export default component.exports