<template lang="pug">
om-modal#upsertPromptModal(
  name="upsert-prompt-modal"
  :width="600"
  color="light"
  @beforeOpen="beforeOpen"
  @beforeClose="beforeClose"
)
  template(slot="modal-header")
    .mb-0.font-weight-bold {{ $t('productPageOptimizer.upsertModal.heading') }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide('upsert-prompt-modal')")
        close-icon(:width="14" :height="14" :color="'#C2C2C2'")
  template(slot="modal-body")
    .d-flex.align-items-end.mb-0
      om-select#prompt-output-type.mr-3(
        :label="$t('productPageOptimizer.upsertModal.outputType')"
        :options="outputTypes"
        v-model="outputType"
      )
      om-input#prompt-variable-name.mr-3(
        :label="$t('productPageOptimizer.upsertModal.displayName')"
        v-model.trim="displayName"
      )
      om-select#chatgpt-version-select(
        :options="modelVersions"
        v-model="modelVersion"
        :label="$t('productPageOptimizer.upsertModal.chatgptVersion')"
      )
    .mb-0.mt-3
      om-checkbox#use-external-data(
        :disabled="externalDataOnly"
        v-model="useExternalData"
        :label="$t('productPageOptimizer.upsertModal.useExternalData')"
      )
      om-input#prompt-variable-name(
        :label="$t('productPageOptimizer.upsertModal.variableName')"
        v-model.trim="variableNamesModel"
        :error="$v.variableNames.$error"
      )
        template(slot="error" v-if="$v.variableNames.$error")
          span {{ $t('productPageOptimizer.upsertModal.variableNameRegexError') }}
    .mb-0
      om-textarea#prompt-text.edited-title(
        :label="$t('productPageOptimizer.upsertModal.prompt')"
        rows="10"
        v-model="promptText"
        required
        style="width: 100%"
      )
    .settings(v-for="(variableName, index) in getVariableNames" :key="index")
      om-heading.mt-3(h3) {{ variableName }}
      .post-format-settings.mt-5
        om-heading.mb-3(h5) {{ $t('productPageOptimizer.upsertModal.postFormatHeading') }}
        .d-flex.align-items-center.justify-content-start.mb-2
          om-checkbox.mr-2(
            :id="`removeTextCheckBox-${index}`"
            :label="$t('productPageOptimizer.upsertModal.postFormatSettings.removeTextLabel')"
            :value="getPostProcessEnabled(variableName, 'removeText')"
            @input="setPostProcessEnabled(variableName, 'removeText', $event)"
          )
          om-input(
            :id="`removeText-${index}`"
            small
            :disabled="!getPostProcessEnabled(variableName, 'removeText')"
            @input="setPostProcessValue(variableName, 'removeText', $event)"
            :value="getPostProcessValue(variableName, 'removeText')"
          )
        .d-flex.align-items-center.justify-content-start.mb-2
          om-checkbox.mr-2(
            :id="`capitalizeFirstLetter-${index}`"
            @input="setPostProcessValue(variableName, 'capitalizeFirstLetter', $event)"
            :value="getPostProcessValue(variableName, 'capitalizeFirstLetter')"
            :label="$t('productPageOptimizer.upsertModal.postFormatSettings.capitalizeFirstLetterLabel')"
          )
        .d-flex.align-items-center.justify-content-start.mb-2
          span.form-check-label.ml-0.mr-2 {{ $t('productPageOptimizer.upsertModal.postFormatSettings.periodAtTheEndLabel') }}
          om-select(
            :options="periodAtTheEndOptions"
            :id="`periodAtTheEnd-${index}`"
            @input="setPeriodAtTheEnd(variableName, $event)"
            :value="getPeriodAtTheEnd(variableName)"
          )
        .d-flex.align-items-center.justify-content-start.mb-2
          om-checkbox#removeApostrophe.mr-2(
            :id="`removeApostrophe-${index}`"
            @input="setPostProcessValue(variableName, 'removeApostrophe', $event)"
            :value="getPostProcessValue(variableName, 'removeApostrophe')"
            :label="$t('productPageOptimizer.upsertModal.postFormatSettings.removeApostrophe')"
          )
      .data-validation-settings.mt-5
        om-heading.mb-3(h5) {{ $t('productPageOptimizer.upsertModal.dataValidationHeading') }}
        .d-flex.align-items-center.justify-content-start.mb-2
          om-checkbox.mr-2(
            :id="`minDescriptionLengthCheckBox-${index}`"
            :value="getDataValidationEnabled(variableName, 'minDescriptionLength')"
            @input="setDataValidationEnabled(variableName, 'minDescriptionLength', $event)"
            :label="$t('productPageOptimizer.upsertModal.dataValidationSettings.minDescriptionLengthLabel')"
          )
          om-input.w-4(
            :id="`minDescriptionLength-${index}`"
            small
            type="number"
            :disabled="!getDataValidationEnabled(variableName, 'minDescriptionLength')"
            @input="setDataValidationValue(variableName, 'minDescriptionLength', +$event)"
            :value="getDataValidationValue(variableName, 'minDescriptionLength')"
          )
        .d-flex.align-items-center.justify-content-start.mb-2
          om-checkbox.mr-2(
            :id="`minResultLengthCheckbox-${index}`"
            :value="getDataValidationEnabled(variableName, 'minResultLength')"
            @input="setDataValidationEnabled(variableName, 'minResultLength', $event)"
            :label="$t('productPageOptimizer.upsertModal.dataValidationSettings.minResultLengthLabel')"
          )
          om-input.w-4(
            :id="`minResultLength-${index}`"
            small
            type="number"
            :disabled="!getDataValidationEnabled(variableName, 'minResultLength')"
            @input="setDataValidationValue(variableName, 'minResultLength', +$event)"
            :value="getDataValidationValue(variableName, 'minResultLength')"
          )
        .d-flex.align-items-center.justify-content-start.mb-2
          om-checkbox.mr-2(
            :id="`maxResultLengthCheckbox-${index}`"
            :value="getDataValidationEnabled(variableName, 'maxResultLength')"
            @input="setDataValidationEnabled(variableName, 'maxResultLength', $event)"
            :label="$t('productPageOptimizer.upsertModal.dataValidationSettings.maxResultLengthLabel')"
          )
          om-input.w-4(
            :id="`maxResultLength-${index}`"
            small
            type="number"
            :disabled="!getDataValidationEnabled(variableName, 'maxResultLength')"
            @input="setDataValidationValue(variableName, 'maxResultLength', +$event)"
            :value="getDataValidationValue(variableName, 'maxResultLength')"
          )
  template(slot="modal-footer")
    .row.d-flex.justify-content-end
      om-button(ghost @click="$modal.hide('upsert-prompt-modal')") {{ $t('cancel') }}
      om-button.ml-3(primary @click="upsertPrompt") {{ $t('save') }}
</template>
<script>
  import UPSERT_PPO_PROMPT from '@/graphql/UpsertPPOPrompt.gql';
  import { chatGPTModelVersions } from '@om/workflow-sppo/src/chatgptModelVersions';

  const _getModelVersions = () => {
    return chatGPTModelVersions.getSelectOptions();
  };

  const _getDefaultModelVersion = () => {
    const modelName = chatGPTModelVersions.getDefaultVersion();
    const displayName = chatGPTModelVersions.getDisplayName(modelName);
    return { key: modelName, value: displayName };
  };

  const DEFAULT_PROMPT_SETTINGS = {
    outputType: 'single',
    dataValidation: [
      {
        variableName: '',
        minDescriptionLength: null,
        minResultLength: null,
        maxResultLength: null,
      },
    ],
    postProcess: [
      {
        variableName: '',
        removeText: null,
        capitalizeFirstLetter: false,
        periodAtTheEnd: 'dontModify',
        removeApostrophe: false,
      },
    ],
  };

  export default {
    data() {
      const modelVersions = _getModelVersions();
      const defaultModelVersion = _getDefaultModelVersion();
      return {
        prompt: null,
        displayName: '',
        variableNames: null,
        variableNamesRegex: /^[a-z0-9:_]*$/i,
        promptText: null,
        domainId: null,
        outputTypes: [
          {
            key: 'single',
            value: 'Single output',
          },
          {
            key: 'multiple',
            value: 'Multiple output',
          },
        ],
        periodAtTheEndOptions: [
          {
            key: 'dontModify',
            value: `Don't modify`,
          },
          {
            key: 'add',
            value: 'Add',
          },
          {
            key: 'remove',
            value: 'Remove',
          },
        ],
        settings: {
          ...DEFAULT_PROMPT_SETTINGS,
        },
        appendix: `\n\nThis is the name of the product:\n{name}\n\nThis is the description of the product:\n\n{description}`,
        modelVersion: defaultModelVersion,
        defaultModelVersion,
        modelVersions,
        useExternalData: false,
        externalDataOnly: false,
      };
    },
    computed: {
      getVariableNames() {
        if (this.settings.outputType === 'single') {
          return [this.variableNames?.[0] ?? ''];
        }
        return this.variableNames;
      },
      periodAtTheEnd: {
        get() {
          return (
            this.periodAtTheEndOptions.find(
              (option) => option.key === this.settings.postProcess.periodAtTheEnd,
            ) ?? this.periodAtTheEndOptions[0]
          );
        },
        set(value) {
          const option = this.periodAtTheEndOptions.find((option) => option.key === value.key);

          if (option) {
            this.settings.postProcess.periodAtTheEnd = option.key;
          }
        },
      },
      outputType: {
        get() {
          return (
            this.outputTypes.find((option) => option.key === this.settings.outputType) ??
            this.outputTypes[0]
          );
        },
        set(value) {
          const option = this.outputTypes.find((option) => option.key === value.key);

          if (option) {
            this.settings.outputType = option.key;
          }
        },
      },
      variableNamesModel: {
        get() {
          if (this.settings.outputType === 'single') {
            return this.variableNames?.[0] ?? '';
          }
          return this.variableNames?.map((variableName) => variableName.trim()).join(',');
        },
        set(value) {
          if (this.settings.outputType === 'single') {
            this.variableNames = [value.trim()];
            return;
          }

          this.variableNames = value.split(',').map((variableName) => variableName.trim());
        },
      },
    },
    watch: {
      variableNames: {
        handler() {
          this.appendSettingsDefaults();
        },
      },
    },
    methods: {
      appendSettingsDefaults() {
        if (this.variableNames) {
          this.settings.postProcess = this.variableNames.map((variableName, index) => {
            let postProcess = this.settings.postProcess.find(
              (setting) => setting.variableName === variableName,
            );
            if (!postProcess) {
              const oldSetting = this.settings.postProcess[index];
              postProcess = oldSetting
                ? { ...this.settings.postProcess[index], variableName }
                : {
                    ...DEFAULT_PROMPT_SETTINGS.postProcess[0],
                    variableName,
                  };
            }

            return postProcess;
          });
          this.settings.dataValidation = this.variableNames.map((variableName, index) => {
            let dataValidation = this.settings.dataValidation.find(
              (setting) => setting.variableName === variableName,
            );
            if (!dataValidation) {
              const oldSetting = this.settings.dataValidation[index];
              dataValidation = oldSetting
                ? { ...this.settings.dataValidation[index], variableName }
                : {
                    ...DEFAULT_PROMPT_SETTINGS.dataValidation[0],
                    variableName,
                  };
            }

            return dataValidation;
          });
        }
      },
      getPeriodAtTheEnd(variableName) {
        return (
          this.periodAtTheEndOptions.find((option) => {
            const postProcessOption = this.settings.postProcess.find(
              (postProcess) => postProcess.variableName === variableName,
            );

            return option.key === postProcessOption?.periodAtTheEnd;
          }) ?? this.periodAtTheEndOptions[0]
        );
      },
      setPeriodAtTheEnd(variableName, value) {
        const option = this.periodAtTheEndOptions.find((option) => option.key === value.key);

        if (option) {
          const postProcessOption = this.settings.postProcess.find(
            (postProcess) => postProcess.variableName === variableName,
          );

          postProcessOption.periodAtTheEnd = value.key;
        }
      },
      getPostProcessEnabled(variableName, key) {
        const option = this.settings.postProcess.find(
          (postProcess) => postProcess.variableName === variableName,
        );
        return option?.[key] !== null;
      },
      setPostProcessEnabled(variableName, key, value) {
        const option = this.settings.postProcess.find(
          (postProcess) => postProcess.variableName === variableName,
        );

        option[key] = value ? '' : null;
      },
      getDataValidationEnabled(variableName, key) {
        const option = this.settings.dataValidation.find(
          (postProcess) => postProcess.variableName === variableName,
        );
        return option?.[key] !== null;
      },
      setDataValidationEnabled(variableName, key, value) {
        const option = this.settings.dataValidation.find(
          (postProcess) => postProcess.variableName === variableName,
        );

        option[key] = value ? '' : null;
      },

      getPostProcessValue(variableName, key) {
        const option = this.settings.postProcess.find(
          (postProcess) => postProcess.variableName === variableName,
        );

        return option?.[key];
      },
      setPostProcessValue(variableName, key, value) {
        const option = this.settings.postProcess.find(
          (postProcess) => postProcess.variableName === variableName,
        );

        option[key] = value;
      },
      getDataValidationValue(variableName, key) {
        const option = this.settings.dataValidation.find(
          (postProcess) => postProcess.variableName === variableName,
        );

        return option?.[key];
      },
      setDataValidationValue(variableName, key, value) {
        const option = this.settings.dataValidation.find(
          (postProcess) => postProcess.variableName === variableName,
        );
        option[key] = value;
      },
      beforeOpen(event = {}) {
        const { params: { prompt, domainId, externalDataOnly = false } = {} } = event;

        this.prompt = prompt ?? null;
        this.displayName = prompt?.displayName ?? null;
        this.variableNames = prompt?.variableNames ?? null;
        this.promptText = prompt?.prompt ?? prompt?.appendix ?? this.appendix;
        this.domainId = domainId;
        this.modelVersion =
          this.modelVersions.find((version) => version.key === prompt?.modelVersion) ??
          this.defaultModelVersion;
        this.useExternalData = prompt.useExternalData ?? false;

        this.externalDataOnly = externalDataOnly;
        if (this.externalDataOnly) {
          this.useExternalData = true;
        }

        this.settings = JSON.parse(
          JSON.stringify({ ...DEFAULT_PROMPT_SETTINGS, ...(prompt?.settings ?? {}) }),
        );
      },
      beforeClose() {
        this.reset();
      },
      reset() {
        this.$v.$reset();
        this.prompt = null;
        this.displayName = '';
        this.variableNames = null;
        this.promptText = null;
        this.domainId = null;
        this.settings = JSON.parse(JSON.stringify(DEFAULT_PROMPT_SETTINGS));
      },
      async upsertPrompt() {
        this.$v.$touch();

        if (this.$v.$error) return;

        const { data, errors } = await this.$apollo.mutate({
          mutation: UPSERT_PPO_PROMPT,
          variables: {
            id: this.prompt?._id,
            prompt: {
              displayName: this.displayName,
              promptText: this.promptText,
              variableNames: this.variableNames,
              domainId: this.domainId,
              modelVersion: this.modelVersion.key,
              settings: this.settings,
              useExternalData: this.useExternalData,
            },
          },
        });

        const response = data?.upsertPPOPrompt;

        if (response) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.saveSuccess'),
          });
          this.reset();
          this.$modal.hide('upsert-prompt-modal');
          this.$emit('upsert', { domainId: this.domainId });
          return;
        }

        const duplicatedVariableError = errors?.find(
          (error) =>
            error.code === 400 && error.message === 'Variables already exists in your shop',
        );

        if (duplicatedVariableError) {
          this.$notify({
            type: 'error',
            text: this.$t('productPageOptimizer.upsertModal.notifications.duplicatedVariables'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.saveError'),
          });
        }
      },
    },
    validations: {
      variableNames: {
        isCool(variableNames) {
          return variableNames.every((value) => this.variableNamesRegex.test(value));
        },
      },
    },
  };
</script>

<style lang="sass" scoped>
  #upsertPromptModal
    overflow: auto
    .select-prompt-output-type
      min-width: 130px
    .select-chatgpt-version-select
      min-width: 170px
    div[class*='select-periodAtTheEnd']
      min-width: 100px
</style>
