<template lang="pug">
om-modal.ppo-auto-generate-modal(
  :name="name"
  width="500"
  color="light"
  @beforeOpen="beforeOpen"
  @closeClicked="close"
  :scrollable="true"
)
  template(#header-title)
    .row
      .col
        .title {{ $t(`productPageOptimizer.autoGenerateModal.title`) }}

  template(#modal-body)
    om-switch#activation.activation(
      v-model.sync="active"
      :label="$t(`productPageOptimizer.autoGenerateModal.enableLabel`)"
    )
    #status-based-options(:class="active ? 'enabled-auto-generate' : 'disabled-auto-generate'")
      om-body-text(bt400xs)
        i18n.step(path="productPageOptimizer.autoGenerateModal.amountLabel" :tag="false")
          template(#amount-input)
            om-input#amount-input.w-6.amount-input(
              :disabled="!active"
              small
              type="number"
              :placeholder="$t(`productPageOptimizer.confirmationModal.generate.all`)"
              v-model.number="amount"
            )

  template(#modal-footer)
    .d-flex.justify-content-end.buttons-wrapper
      om-button.cancel-btn(secondary @click="close") {{ $t('cancel') }}
      om-button.confirm-btn(primary @click="save") {{ $t('save') }}
</template>
<script>
  import UPDATE_PPO_PROMPT_AUTO_GENERATION from '@/graphql/UpdatePPOPromptAutoGeneration.gql';

  export default {
    data: () => ({
      name: 'ppo-auto-generate-modal',
      params: {},
      active: false,
      amount: null,
      generationMode: 'generateMissing',
    }),
    methods: {
      async beforeOpen({ params: { prompt } }) {
        this.params = {
          prompt,
        };
        const settings = prompt.autoGeneration?.settings;
        if (!settings) return;
        this.active = settings.enabled;
        this.amount = settings.requestedProductAmount || null;
      },
      close() {
        this.reset();
        this.$modal.hide(this.name);
      },
      reset() {
        this.active = null;
        this.amount = null;
      },
      async save() {
        const { promptId, enabled, requestedProductAmount, generationMode } = {
          promptId: this.params.prompt._id,
          enabled: this.active,
          requestedProductAmount: this.amount || 0,
          generationMode: this.generationMode,
        };
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_PPO_PROMPT_AUTO_GENERATION,
          variables: {
            id: promptId,
            settings: {
              enabled,
              requestedProductAmount,
              generationMode,
            },
          },
        });

        const response = !!data?.updatePPOPromptAutoGeneration.success;

        if (response) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.saveSuccess'),
          });
          this.$emit('update');
          this.reset();
          this.close();
          return;
        }

        this.$notify({
          type: 'error',
          text: this.$t('notifications.saveError'),
        });
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .ppo-auto-generate-modal

    ::v-deep .body-text,
    ::v-deep .switch-label
      color: $om-dark-grey

    .disabled-auto-generate
      ::v-deep .body-text
        color: $om-light-grey !important

    .title
      font-weight: 700
      font-size: 18px
      line-height: 24px
    .confirm-btn
      margin-left: 10px

    .amount-input
      display: inline-block

    .activation
      margin-bottom: 20px

      ::v-deep .switch-label
        margin-top: 8px
        flex: 0 0 80%
        max-width: 80%

      ::v-deep .switch-wrapper
        flex: 0 0 20%
        max-width: 20%
</style>
