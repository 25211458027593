<template lang="pug">
om-modal.sp-manager-modal(
  :name="name"
  width="800"
  color="light"
  @beforeOpen="beforeOpen"
  @closeClicked="close"
  :scrollable="true"
)
  template(#header-title)
    .row
      .col
        .title {{ $t('experimental.smartPersonalization.managerModal.title') }}
  template(#modal-body)
    .input-label {{ $t('experimental.smartPersonalization.managerModal.parameter') }}
    input.parameter-input.form-control.form-control-md.mb-5(
      type="text"
      :disabled="!isNew"
      v-model="personalization.parameter"
    )
    .advanced-settings-button.mb-6(@click="toggleAdvancedSettings")
      span {{ $t('experimental.smartPersonalization.managerModal.advancedSettings') }}
      arrow(:direction="isAdvancedSettingsVisible ? 'down' : 'right'")
    .advanced-settings(v-show="isAdvancedSettingsVisible")
      .input-label {{ $t('experimental.smartPersonalization.managerModal.prompt') }}
      om-textarea.mb-6(v-model="personalization.prompt")
    .personalization-values
      .personalization-values-header.mb-3
        .personalization-values-header-value {{ $t('experimental.smartPersonalization.managerModal.value') }}
        .personalization-values-header-context {{ $t('experimental.smartPersonalization.managerModal.context') }}
      .value-row.mb-2(v-for="row in personalization.values")
        input.value-input.form-control.form-control-md(
          type="text"
          v-model="row.value"
          @input="onValueInput(row)"
        )
        om-textarea.context-input(v-model="row.context")
  template(#modal-footer)
    .d-flex.justify-content-end.buttons-wrapper
      om-button.mr-4(primary @click="addNewValue") {{ $t('experimental.smartPersonalization.managerModal.newValue') }}
      .d-flex.align-items-center.mr-auto {{ $t('experimental.smartPersonalization.managerModal.autoPersonalization') }}
        om-switch#auto-personalization.ml-2(
          :value="personalization.autoPersonalization"
          @switch="toggleAutoPersonalization"
        )
      om-button.cancel-btn(ghost @click="close") {{ $t('cancel') }}
      om-button.save-btn(primary :disabled="!isSaveEnabled" @click="save") {{ $t('save') }}
</template>

<script>
  import Arrow from '@/assets/admin/svg/icons/Arrow.vue';
  import { cloneDeep as _clone } from 'lodash-es';
  import GET_SP_PROMPT from '@/graphql/GetDefaultSmartPersonalizationPrompt.gql';

  export default {
    components: { Arrow },
    data: () => ({
      name: 'sp-manager-modal',
      params: {},
      isNew: true,
      personalization: {
        autoPersonalization: false,
        parameter: '',
        prompt: '',
        values: [],
      },
      isAdvancedSettingsVisible: false,
    }),
    computed: {
      isSaveEnabled() {
        return !!this.personalization.parameter;
      },
    },
    methods: {
      beforeOpen({ params }) {
        this.params = params;
        this.reset();
        if (params.data) {
          this.isNew = false;
          const { parameter, prompt, values, autoPersonalization } = _clone(params.data);
          this.personalization.parameter = parameter;
          this.personalization.prompt = prompt;
          this.personalization.autoPersonalization = autoPersonalization;
          if (values.length) {
            this.personalization.values = values;
          }
        } else {
          this.setDefaultPrompt();
        }
      },
      async setDefaultPrompt() {
        const { data } = await this.$apollo.query({
          query: GET_SP_PROMPT,
        });

        if (data?.prompt) {
          this.personalization.prompt = data.prompt;
        }
      },
      reset() {
        this.isNew = true;
        this.isAdvancedSettingsVisible = false;
        this.personalization = {
          autoPersonalization: true,
          parameter: '',
          prompt: '',
          values: [{ value: '', context: '' }],
        };
      },
      save() {
        this.removeEmptyRows();
        this.$emit('save', {
          domainId: this.params.domainId,
          personalization: this.personalization,
        });
        this.close();
      },
      close() {
        this.$modal.hide(this.name);
      },
      toggleAdvancedSettings() {
        this.isAdvancedSettingsVisible = !this.isAdvancedSettingsVisible;
      },
      addNewValue() {
        this.personalization.values.push({ value: '', context: '' });
      },
      toggleAutoPersonalization() {
        this.personalization.autoPersonalization = !this.personalization.autoPersonalization;
      },
      onValueInput(row) {
        if (this.personalization.autoPersonalization) {
          row.context = row.value;
        }
      },
      removeEmptyRows() {
        this.personalization.values = this.personalization.values.filter(({ value }) => !!value);
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .sp-manager-modal
    font-size: 14px
    .title
      font-weight: 700
      font-size: 18px
      line-height: 24px
    .save-btn
      margin-left: 10px
    .input-label
      color: $om-gray-700
      font-size: 12px
      line-height: 24px
    .parameter-input
      width: 340px
    .advanced-settings-button
      display: flex
      align-items: center
      cursor: pointer
    .personalization-values
      &-header
        display: flex
        font-size: 16px
        font-weight: 700
        color: $om-gray-700
        &-value
          width: 200px
      .value-row
        display: flex
        .value-input
          width: 180px
          margin-right: 20px
        .context-input
          width: 100%
          textarea
            height: 40px
</style>
