import CampaignsAbTest from './CampaignsAbTest.vue';
import GlobalFrequencyCap from './GlobalFrequencyCap.vue';
import ProductPageOptimizer from './ProductPageOptimizer.vue';
import DomainContext from './DomainContext.vue';
import SmartPersonalization from './SmartPersonalization.vue';
import SmartProductRecommendation from './SmartProductRecommendation.vue';
import Feeds from './Feeds.vue';
import NonstandardOrderDomains from './NonstandardOrderDomains.vue';

export default {
  ProductPageOptimizer,
  CampaignsAbTest,
  GlobalFrequencyCap,
  DomainContext,
  SmartPersonalization,
  SmartProductRecommendation,
  Feeds,
  NonstandardOrderDomains,
};
