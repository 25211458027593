<template lang="pug">
.smart-personalization
  h3.mb-6 {{ $t('experimental.smartPersonalization.heading') }}
  .sp-domain-wrapper.mb-6(v-for="(domain, index) in domains" :key="index")
    h3.mb-3 {{ domain.domain }}
    .d-flex(v-if="dataByDomain(domain._id)")
      om-button.mr-2(primary @click="openSPModal(domain._id)") {{ $t('experimental.smartPersonalization.addParameter') }}
      om-link.sp-export-import-link(
        primary
        small
        withIconLeft
        @click="openSPExportImportModal(domain)"
      ) SP import/export
        template(slot="left-icon")
          UilFileExport.mr-1(size="1.5rem")
    om-button.mr-2(primary @click="setupPersonalization(domain._id)" v-else) {{ $t('experimental.smartPersonalization.setupPersonalization') }}
    .table.brand-table.overflow-y-auto.col.px-0.table-column-secondary.mt-6(
      v-if="dataByDomain(domain._id)"
    )
      .thead.scroll.mb-0
        .tr
          .th(scope="col" v-for="(column, index) in columns" :class="[`brand-table-th-${index}`]")
            span.table-column-header.d-flex.pb-0.justify-content-start
              span.title.d-flex.cursor-pointer.m-3.pb-0 {{ column }}
      .tbody
        template(v-for="(data, index) in dataByDomain(domain._id)")
          .tr.brand-table-tr.brand-table-tr-header(:key="index")
            .td.brand-table-td.brand-table-td-0.text-left.m-3 {{ data.parameter }}
            .td.brand-table-td.brand-table-td-0.text-left.m-3 {{ data.values.length }}
            .td.brand-table-td.brand-table-td-0.text-left.m-3
              .d-flex.flex-row.justify-content-start
                om-button.mr-2(
                  secondary
                  small
                  icon="edit-alt"
                  @click="openSPModal(domain._id, data)"
                  icon-only
                )
                om-button.mr-2(
                  secondary
                  small
                  icon="trash-alt"
                  @click="openDeleteConfirmationModal(domain._id, data.parameter)"
                  icon-only
                )
  delete-confirmation-modal(@confirm="confirmDelete")
  personalization-manager-modal(@save="save")
  sp-import-export
</template>
<script>
  import { mapGetters } from 'vuex';
  import { UilFileExport } from '@iconscout/vue-unicons';
  import GET_SP_DATA from '@/graphql/GetSmartPersonalizationData.gql';
  import SETUP_SP_DATA from '@/graphql/SetupSmartPersonalizationData.gql';
  import DELETE_SP_PARAMETER from '@/graphql/DeleteSmartPersonalizationParameter.gql';
  import SAVE_SP_PARAMETER from '@/graphql/SaveSmartPersonalizationParameter.gql';
  import DeleteConfirmationModal from '@/components/Modals/SmartPersonalization/DeleteConfirmationModal.vue';
  import PersonalizationManagerModal from '@/components/Modals/SmartPersonalization/PersonalizationManagerModal.vue';

  export default {
    components: {
      DeleteConfirmationModal,
      PersonalizationManagerModal,
      UilFileExport,
      'sp-import-export': () => import('@/components/SmartPersonalization/Modal/ImportExport.vue'),
    },
    data() {
      return {
        personalizationData: {},
        columns: [
          this.$t('experimental.smartPersonalization.columns.parameter'),
          this.$t('experimental.smartPersonalization.columns.context'),
          this.$t('experimental.smartPersonalization.columns.actions'),
        ],
      };
    },
    computed: {
      ...mapGetters(['domains']),
    },
    mounted() {
      this.getSPData();
    },
    methods: {
      async setupPersonalization(domainId) {
        const { data } = await this.$apollo.mutate({
          mutation: SETUP_SP_DATA,
          variables: {
            domainId,
          },
        });

        data.setupSmartPersonalizationData.forEach((item) => {
          this.setDataForDomain(item);
        });
      },
      setDataForDomain(data) {
        const item = {
          autoPersonalization: data.autoPersonalization,
          parameter: data.parameter,
          prompt: data.prompt,
          values: data.values,
        };
        if (this.personalizationData[data.domainId]) {
          this.personalizationData[data.domainId].push(item);
        } else {
          this.$set(this.personalizationData, data.domainId, [item]);
        }
      },
      openSPModal(domainId, data) {
        this.$modal.show('sp-manager-modal', { domainId, data });
      },
      openSPExportImportModal(domain) {
        this.$modal.show('sp-import-export', { domain });
      },
      async getSPData() {
        this.personalizationData = {};
        const { data } = await this.$apollo.query({
          query: GET_SP_DATA,
        });

        data.personalizationData.forEach((item) => {
          this.setDataForDomain(item);
        });
      },
      dataByDomain(domainId) {
        return this.personalizationData[domainId];
      },
      openDeleteConfirmationModal(domainId, parameter) {
        this.$modal.show('sp-delete-confirmation-modal', { domainId, parameter });
      },
      async confirmDelete({ domainId, parameter }) {
        let success;
        try {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE_SP_PARAMETER,
            variables: {
              domainId,
              parameter,
            },
          });

          if (data.deleteSmartPersonalizationParameter.success) {
            success = true;
          } else {
            success = false;
          }
        } catch (e) {
          success = false;
        }

        if (success) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.removeSuccess'),
          });
          this.getSPData();
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.removeError'),
          });
        }
      },
      async save({ domainId, personalization }) {
        let success;
        try {
          const { data } = await this.$apollo.mutate({
            mutation: SAVE_SP_PARAMETER,
            variables: {
              input: {
                domainId,
                ...personalization,
              },
            },
          });

          if (data.saveSmartPersonalizationParameter.success) {
            success = true;
          } else {
            success = false;
          }
        } catch (e) {
          success = false;
        }

        if (success) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.saveSuccess'),
          });
          this.getSPData();
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.saveError'),
          });
        }
      },
    },
  };
</script>
