<template lang="pug">
div
  om-modal(name="delete-cookie" modalClasses="delete-cookie-modal" :width="480" color="light")
    template(slot="modal-header")
      .mb-0.font-weight-bold {{ $t('experimental.campaignsAbTest.deleteModal.title') }}
      .brand-modal-action-icon
        span.cursor-pointer(@click="$modal.hide('delete-cookie')")
          close-icon(:width="14" :height="14" :color="'#C2C2C2'")
    template(slot="modal-body")
      .mb-0 {{ $t('experimental.campaignsAbTest.deleteModal.subTitle', { testCase: caseName }) }}
    template(slot="modal-footer")
      .row
        om-button(ghost @click="$modal.hide('delete-cookie')") {{ $t('cancel') }}
        om-button.ml-3(primary @click="deleteCookie(caseID)") {{ $t('delete') }}
  h3.mb-3 {{ $t('experimental.campaignsAbTest.heading') }}
  .ab-test-case-wrapper
    h5.mb-3 {{ $t('experimental.campaignsAbTest.activeTestsHeading') }}
    .ab-test-case-saved.mb-4
      div(v-if="testCases.length === 0") {{ $t('experimental.campaignsAbTest.noActiveTests') }}

      .ab-test-case-item.py-1.font-weight-bold(
        v-else
        v-for="testCase in testCases"
        :key="testCase.id"
      )
        .row.ml-1
          fa-icon(
            @click.native="openDeleteModal(testCase.id, testCase.name)"
            variant="fa-trash"
            size="1.2"
          )
          fa-icon.ml-2(@click.native="updateTestCase(testCase)" variant="fa-edit" size="1.2")
          .ml-2 {{ testCase.name }}
        .row
          .bordered.ml-2(v-for="(cookieName, index) in testCase.cookies")
            om-tag(
              background="var(--brand-secondary-color)"
              color="var(--brand-primary-color)"
              :key="cookieName.cookie"
              small
              :removable="true"
            ) {{ cookieName.cookie }}
            om-tag.ml-1(
              background="var(--brand-secondary-color)"
              color="var(--brand-primary-color)"
              :key="cookieName.ratio"
              small
              :removable="true"
            ) {{ cookieName.ratio }} %
    h5.mb-3 {{ isEditing ? $t('experimental.campaignsAbTest.newTestCase.editHeading') : $t('experimental.campaignsAbTest.newTestCase.heading') }}
    .row.ab-test-case-item
      om-input#new-test-case-name-input.mb-3.col-3(
        v-model="newTestCaseName"
        :disabled="isEditing"
        :label="$t('experimental.campaignsAbTest.newTestCase.label')"
        :placeholder="$t('experimental.campaignsAbTest.newTestCase.placeholder')"
        :required="false"
      )
        template(v-if="errorKey" #error)
          span {{ $t(`experimental.campaignsAbTest.errors.${errorKey}`) }}

      om-input#new-test-case-split-input.mb-3.col-2(
        v-model="splits"
        type="number"
        :disabled="isEditing"
        :label="$t('experimental.campaignsAbTest.numberOfSplit.label')"
        :placeholder="$t('experimental.campaignsAbTest.numberOfSplit.placeholder')"
        :required="true"
      )
        template(v-if="splitError || invalidRatio" #error)
          span {{ $t(`experimental.campaignsAbTest.errors.${splitError || invalidRatio}`) }}

      om-input.mb-2.px-0.col-1.mr-2(
        v-for="(ratio, i) in ratios"
        :key="i"
        :id="'ratio-' + i"
        v-model="ratios[i]"
        type="number"
        :label="$t('experimental.campaignsAbTest.splitRatio') + getSplitName(i)"
        :required="true"
      )
    .row
      om-code-input#new-test-case-js-snippet-input.mb-3.code-input.col(
        v-model="newTestJSSnippet"
        :label="$t('experimental.campaignsAbTest.newTestCase.jsSnippetLabel')"
        :required="false"
      )
    .row.px-2.mt-2
      om-button(primary small @click="save") {{ isEditing ? $t('save') : $t('add') }}
      om-button(v-if="isEditing" small @click="reset") {{ $t('cancel') }}
</template>
<script>
  import slugify from 'slugify';
  import { nanoid } from 'nanoid';
  import DELETE_COOKIE from '@/graphql/DeleteCookie.gql';
  import { getBrandedClassString } from '@/components/Elements/Button';

  const ABC_FIRST_FIVE_CHAR = ['Control', 'A', 'B', 'C', 'D'];

  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return { testCases: [] };
        },
      },
    },
    data: () => ({
      testCaseId: null,
      newTestCaseName: null,
      newTestCaseId: null,
      newTestJSSnippet: null,
      errorKey: null,
      splitError: null,
      invalidRatio: null,
      splits: 2,
      ratios: ['50', '50'],
      caseID: null,
      caseName: null,
    }),
    computed: {
      testCases() {
        return this.data.testCases;
      },
      isEditing() {
        return !!this.testCaseId;
      },
    },
    watch: {
      splits(v) {
        const nrOfSplits = parseInt(v, 10);
        if (!(nrOfSplits <= 5 && nrOfSplits >= 2)) {
          this.splitError = 'invalidValue';
          this.ratios = [];
          return;
        }
        if (nrOfSplits !== this.ratios.length) {
          this.ratios = this.calculateRatios();
        }
        this.splitError = null;
      },
      ratios(v) {
        if (v.length > 1) {
          const sumOfRatios = v.reduce(
            (accumulator, current) => parseInt(accumulator, 10) + parseInt(current, 10),
          );

          if (sumOfRatios > 100) {
            this.invalidRatio = 'invalidRatio';
          } else {
            this.invalidRatio = null;
          }
        }
      },
    },
    methods: {
      async deleteCookie(testCaseID) {
        const {
          data: { deleteCookie },
        } = await this.$apollo.mutate({
          mutation: DELETE_COOKIE,
          variables: {
            customId: testCaseID,
          },
        });

        if (deleteCookie.success) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.removeSuccess'),
          });
          this.$bus.$emit('reFetechExperimentalSettings');
          this.$modal.hide('delete-cookie');
        } else {
          this.$modal.show('dialog', {
            text: this.$t('fieldUsed', { count: deleteCookie.count }),
            buttons: [
              {
                title: this.$t('ok'),
                class: getBrandedClassString({ secondary: true }),
                default: true,
              },
            ],
          });
        }
      },
      openDeleteModal(caseID, testCaseName) {
        this.caseID = caseID;
        this.caseName = testCaseName;
        this.$modal.show('delete-cookie');
      },
      calculateRatios() {
        const nrOfSplits = parseInt(this.splits, 10);

        if (nrOfSplits === 3) {
          return [33, 33, 34];
        }

        return Array(nrOfSplits).fill(100 / nrOfSplits);
      },
      slugify(input) {
        return slugify(input, '_');
      },
      generateCookieNames(name, id) {
        const slugified = this.slugify(name).substring(0, 15);
        const unique = `${slugified}_${id}`;
        return Array.from({ length: this.splits }, (_, i) => {
          return { cookie: `${unique}_${ABC_FIRST_FIVE_CHAR[i]}`, ratio: this.ratios[i] };
        });
      },
      alreadyAdded(name) {
        return this.testCases.some(({ name: savedName }) => name === savedName);
      },
      syntaxCheckJS(code) {
        try {
          // eslint-disable-next-line no-eval
          eval(`(function () {${code}})`);

          return true;
        } catch (err) {
          console.error(err);
          return false;
        }
      },

      updateTestCase(testCase) {
        this.testCaseId = testCase.id;
        this.newTestCaseName = testCase.name;
        this.splits = testCase.cookies.length;
        this.ratios = testCase.cookies.map(({ ratio }) => ratio);
        this.newTestJSSnippet = testCase.jsSnippet;
      },
      reset() {
        this.testCaseId = null;
        this.newTestCaseName = '';
        this.splits = 2;
        this.newTestJSSnippet = '';
        this.errorKey = null;
      },
      getSplitName(index) {
        return ABC_FIRST_FIVE_CHAR[index];
      },

      save() {
        this.errorKey = null;

        const id = this.isEditing ? this.testCaseId : nanoid(9);
        const name = (this.newTestCaseName || '').trim();
        const jsSnippet = this.newTestJSSnippet;
        if (!name) {
          this.errorKey = 'emptyName';
          return;
        }

        if (!this.isEditing && this.alreadyAdded(name)) {
          this.errorKey = 'alreadyAdded';
          return;
        }

        if (jsSnippet && !this.syntaxCheckJS(jsSnippet)) {
          this.errorKey = 'jsSyntaxError';
          return;
        }

        const cookies = this.generateCookieNames(name, id);
        this.$emit(this.isEditing ? 'update' : 'add', { id, name, cookies, jsSnippet });
        this.reset();
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../sass/variables/_colors.sass'

  .copy-icon
    color: $om-light-grey-2
  .bordered
    border: 1px solid #d5d8dd
    border-radius: 4px
  .code-input
    height: 300px
</style>
