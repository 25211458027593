<template lang="pug">
om-modal.ppo-confirmation-modal(
  :name="name"
  width="500"
  color="light"
  @beforeOpen="beforeOpen"
  @closeClicked="close"
  :scrollable="true"
)
  template(#header-title)
    .row
      .col
        .title {{ $t(`productPageOptimizer.confirmationModal.${params.action}.title`) }}

  template(#modal-body)
    template(v-if="params.action === 'generate' || params.action === 'export-results'")
      om-body-text(bt400xs) {{ $t(`productPageOptimizer.confirmationModal.${useExternalData ? 'availableItems' : 'availableProducts'}`) }}
        om-body-text.ml-1.d-inline(bt700xs) {{ itemCount }}
      om-body-text(bt400xs)
        i18n.step(
          :path="`productPageOptimizer.confirmationModal.${params.action}.description`"
          :tag="false"
        )
          template(#amount-input)
            om-input#amount-input.w-6.amount-input(
              small
              type="number"
              :placeholder="$t(`productPageOptimizer.confirmationModal.${params.action}.all`)"
              v-model.number="amount"
            )
          template(#prompt-name v-if="params.prompt")
            span {{ params.prompt.variableNames.join(', ') }}
          template(#prompt-names v-if="params.prompts")
            b {{ params.prompts.flatMap(({ variableNames }) => variableNames).join(', ') }}
      label.font-size-0--75.font-weight-bold(
        for="generation-mode"
        v-if="params.action === 'generate'"
      ) {{ $t(`productPageOptimizer.confirmationModal.${params.action}.generationMode`) }}
      om-select#generation-mode.w-50(
        :options="generationModeOptions"
        @input="setGenerationMode($event)"
        :value="getGenerationMode()"
        v-if="params.action === 'generate'"
      )

    template(v-if="params.action === 'delete'")
      om-body-text(bt400md) {{ params.prompt.displayName }}
        .brand-under-heading-text {{ params.prompt.variableNames.join(', ') }}

  template(#modal-footer)
    .d-flex.justify-content-end.buttons-wrapper
      om-button.cancel-btn(secondary @click="close") {{ $t('cancel') }}
      om-button.confirm-btn(primary @click="sure") {{ params.action === 'generate' ? $t('generate') : $t('sure') }}
</template>

<script>
  import GET_AVAILABLE_SPPO_ITEM_COUNT from '@/graphql/GetAvailableSPPOItemCount.gql';

  export default {
    data: () => ({
      name: 'ppo-confirmation-modal',
      amount: null,
      params: { action: 'none' },
      itemCount: 'N/A',
      generationMode: null,
      generationModes: ['generateAll', 'generateMissing'],
    }),
    computed: {
      generationModeOptions() {
        return this.generationModes.map((mode) => {
          return {
            key: mode,
            value: this.$t(`productPageOptimizer.confirmationModal.generate.${mode}`),
          };
        });
      },
      useExternalData() {
        if (this.params.prompt) {
          return this.params.prompt.useExternalData;
        }
        if (this.params.prompts) {
          return this.params?.prompts?.every((prompt) => prompt.useExternalData);
        }
        return false;
      },
    },
    methods: {
      async beforeOpen({
        params: {
          domainId,
          prompt,
          prompts = [],
          amount = 20,
          action,
          generationMode = this.generationModes[0],
        },
      }) {
        this.params = {
          domainId,
          prompt,
          prompts,
          action,
        };
        if (['generate', 'export-results'].includes(this.params.action)) {
          this.setGenerationMode({ key: generationMode });
          this.amount = action === 'export-results' ? null : amount;
          this.itemCount = 'Loading...';
          this.itemCount = await this.getAvailableItemCount();
        }
      },
      getGenerationMode() {
        return (
          this.generationModeOptions.find((option) => option.key === this.generationMode) ??
          this.generationModeOptions[0]
        );
      },
      setGenerationMode(event) {
        const option = this.generationModes.find((option) => option === event?.key);
        if (option) {
          this.generationMode = option;
        }
      },
      sure() {
        this.$emit('sure', {
          ...this.params,
          ...(['generate', 'export-results'].includes(this.params.action)
            ? {
                amount: this.amount || null,
                generationMode: this.generationMode ?? this.generationModes[0].key,
                useExternalData: this.useExternalData,
              }
            : {}),
        });
        this.$modal.hide(this.name);
      },
      close() {
        this.$modal.hide(this.name);
      },
      async getAvailableItemCount() {
        if (!this.params.domainId) return 'N/A';

        const { data } = await this.$apollo.query({
          query: GET_AVAILABLE_SPPO_ITEM_COUNT,
          variables: {
            domainId: this.params.domainId,
            useExternalData: this.useExternalData,
          },
        });

        if (!parseInt(data?.count, 10) && parseInt(data?.count, 10) !== 0) {
          console.debug('Available item count is not a number, skipping');
          return;
        }

        return data.count;
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .ppo-confirmation-modal
    .title
      font-weight: 700
      font-size: 18px
      line-height: 24px
    .confirm-btn
      margin-left: 10px
    .description
      max-width: 320px

    .amount-input
      display: inline-block
</style>
