<template lang="pug">
#feeds-experimental
  h3.mb-6 {{ $t('feeds.title') }}
  .feeds-wrapper
    .feed-add-wrapper
      .title {{ $t('feeds.newFeed.title') }}
      feed-add
    .feed-table-wrapper.mt-3
      feed-table
</template>
<script>
  export default {
    components: {
      FeedAdd: () => import('@/components/Feeds/FeedAdd.vue'),
      FeedTable: () => import('@/components/Feeds/FeedTable.vue'),
    },
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>
