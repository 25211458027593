<template lang="pug">
div
  om-heading.mb-3(h3) {{ $t('experimental.globalFrequencyCap.heading') }}
  .gfc-wrapper
    .gfc-setting.mb-2.d-flex.align-items-center
      span.mr-2 {{ $t('experimental.globalFrequencyCap.texts.pre') }}
      om-input#max-appears-count.w-4(v-model.number="count" small type="number")
        template(v-if="errorKey && errorKey.includes('wrongCount')" #error)
          span {{ $t(`experimental.globalFrequencyCap.errors.${errorKey}`) }}
      span.mx-2 {{ $t('experimental.globalFrequencyCap.texts.between') }}
      om-input#max-appears-in-time.w-4.mr-2(v-model.number="inTime" small type="number")
        template(v-if="errorKey && errorKey.includes('inTime')" #error)
          span {{ $t(`experimental.globalFrequencyCap.errors.${errorKey}`) }}
      select#max-appears-time-unit.form-control.w-8(
        v-model="timeUnit"
        :class="{ 'is-invalid': errorKey === 'noUnit' }"
      )
        option(value="minutes") {{ $t('minutes') }}
        option(value="hours") {{ $t('hours') }}
      span.ml-2(v-if="$i18n.locale === 'hu'") {{ $t('experimental.globalFrequencyCap.texts.post') }}
    .gfc-setting.mb-2.d-flex.align-items-center
      span.mr-2 {{ $t('experimental.globalFrequencyCap.texts.exceptCampaigns') }}
      om-input#limitless-campaigns.w-15(
        v-model="limitlessCampaignIds"
        small
        placeholder="e.g. 332,333,334"
      )
        template(v-if="errorKey && errorKey.includes('invalidExceptions')" #error)
          span {{ $t(`experimental.globalFrequencyCap.errors.${errorKey}`) }}
    .d-flex.mt-3
      om-button.mr-2(primary small @click="add") {{ $t('save') }}
      om-button(secondary ghost small @click="reset") {{ $t('reset') }}
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return {
            count: null,
            inTime: null,
            timeUnit: 'minutes',
          };
        },
      },
    },
    data: () => ({
      errorKey: null,
      count: null,
      inTime: null,
      limitlessCampaignIds: null,
      timeUnit: 'minutes',
    }),
    watch: {
      data: {
        deep: true,
        handler(n) {
          this.setLocalValues(n);
        },
      },
    },
    mounted() {
      // TODO[gergogy] Make correct property binding in Elements/Input.vue
      const inputs = document.querySelectorAll('#max-appears-count, #max-appears-in-time');
      if (inputs && inputs.length) {
        for (const input of inputs) {
          input.setAttribute('min', '1');
        }
      }
      this.setLocalValues(this.data);
    },
    methods: {
      checkErrors() {
        if (!this.count || this.count < 1) {
          this.errorKey = 'wrongCount';
          return true;
        }

        if (!this.timeUnit) {
          this.errorKey = 'noUnit';
          return true;
        }

        if (this.timeUnit === 'minutes' && (this.inTime < 1 || this.inTime > 60)) {
          this.errorKey = 'inTime1-60';
          return true;
        }

        if (this.timeUnit === 'hours' && (this.inTime < 1 || this.inTime > 24)) {
          this.errorKey = 'inTime1-24';
          return true;
        }

        if (this.limitlessCampaignIds) {
          const parsedValue = this.limitlessCampaignIds
            .split(',')
            .map((str) => parseInt(str, 10))
            .join(',');

          if (parsedValue !== this.limitlessCampaignIds) {
            this.errorKey = 'invalidExceptions';
            return true;
          }
        }

        return false;
      },
      setLocalValues(data) {
        const { count, inTime, timeUnit, limitlessCampaignIds } = data;
        if (count) this.count = count;
        if (inTime) this.inTime = inTime;
        if (timeUnit) this.timeUnit = timeUnit;
        if (Array.isArray(limitlessCampaignIds))
          this.limitlessCampaignIds = limitlessCampaignIds.join(',');
      },
      add() {
        if (this.checkErrors()) return;
        const { count, inTime, timeUnit, limitlessCampaignIds } = this;
        this.$emit('add', {
          count,
          inTime,
          timeUnit,
          limitlessCampaignIds: limitlessCampaignIds
            ? limitlessCampaignIds.split(',').map((str) => parseInt(str, 10))
            : null,
        });
      },
      reset() {
        this.count = null;
        this.inTime = null;
        this.timeUnit = 'minutes';
        this.limitlessCampaignIds = null;
        this.$emit('add', { reset: true });
      },
    },
  };
</script>
