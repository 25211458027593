<template lang="pug">
.domain-context
  h3.mb-6 {{ $t('experimental.domainContext.heading') }}
  om-select#domainSelect(
    v-model="selectedDomain"
    :options="domainOptions"
    searchable
    :label="$t('experimental.domainContext.domainSelectLabel')"
    :placeholder="$t('experimental.domainContext.domainSelectPlaceholder')"
  )
  om-textarea#domainContext.mt-5.domain-context-textarea(
    v-if="selectedDomain"
    v-model="domainContextValue"
  )
  .row.px-2.mt-4
    om-button(primary small @click="save" v-if="selectedDomain") {{ $t('save') }}
    om-button.generate-context-button(
      primary
      small
      @click="generate"
      v-if="selectedDomain"
      :loading="generateLoading"
    ) {{ $t('generate') }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import GET_DOMAIN_CONTEXTS from '@/graphql/GetDomainContexts.gql';
  import domainContextMixin from '@/mixins/domainContext';

  export default {
    mixins: [domainContextMixin],
    data: () => ({
      selectedDomain: null,
      domainContextValue: '',
      siteContent: '',
      contexts: {},
      generateLoading: false,
    }),
    computed: {
      ...mapGetters(['domains']),
      domainOptions() {
        return this.domains.map((domain) => ({
          key: domain._id,
          value: domain.domain,
        }));
      },
      domainId() {
        return this.selectedDomain?.key;
      },
    },
    watch: {
      selectedDomain({ key }) {
        this.domainContextValue = this.contexts[key];
      },
      domainContextValue(v) {
        this.contexts[this.selectedDomain.key] = v;
      },
    },
    async mounted() {
      const {
        data: { domainContexts },
      } = await this.$apollo.query({
        query: GET_DOMAIN_CONTEXTS,
      });

      this.domains.forEach((domain) => {
        const existingContext = domainContexts.find(({ domainId }) => domainId === domain._id);
        this.contexts[domain._id] = existingContext?.context || '';
      });
    },
    methods: {
      async save() {
        const context = this.contexts[this.domainId];

        let success;
        try {
          const response = await this.setDomainContext(this.domainId, context, this.siteContent);

          success = !!response.data.setDomainContext.success;
        } catch (e) {
          success = false;
        }

        if (success) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.domainContext.saveSuccessful'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.domainContext.saveError'),
          });
        }
      },
      async generate() {
        this.generateLoading = true;
        let success;
        let context;
        let siteContent;

        try {
          const response = await this.generateDomainContext(this.domainId);

          const result = response.data.generateDomainContext;
          success = !!result.success;
          context = result.context;
          siteContent = result.siteContent;
        } catch (e) {
          success = false;
        }

        this.generateLoading = false;

        if (success) {
          this.contexts[this.domainId] = context;
          this.domainContextValue = context;
          this.siteContent = siteContent;
          this.$notify({
            type: 'success',
            text: this.$t('notifications.domainContext.generateSuccessful'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.domainContext.generateError'),
          });
        }
      },
    },
  };
</script>

<style lang="sass">
  .domain-context-textarea
    textarea
      min-height: 250px
  .generate-context-button
    margin-left: auto
</style>
