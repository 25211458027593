<template lang="pug">
om-modal.sp-delete-confirmation-modal(
  :name="name"
  width="500"
  color="light"
  @beforeOpen="beforeOpen"
  @closeClicked="close"
)
  template(#header-title)
    .row
      .col
        .title {{ $t('experimental.smartPersonalization.deleteConfirmModal.title') }}

  template(#modal-body)
    om-body-text(
      bt400xs
      v-html="$t('experimental.smartPersonalization.deleteConfirmModal.content', { parameter: params.parameter })"
    )
  template(#modal-footer)
    .d-flex.justify-content-end.buttons-wrapper
      om-button.cancel-btn(secondary @click="close") {{ $t('cancel') }}
      om-button.confirm-btn(primary @click="confirm") {{ $t('delete') }}
</template>

<script>
  export default {
    data: () => ({
      name: 'sp-delete-confirmation-modal',
      params: {},
    }),
    methods: {
      beforeOpen({ params }) {
        this.params = params;
      },
      confirm() {
        this.$emit('confirm', this.params);
        this.close();
      },
      close() {
        this.$modal.hide(this.name);
      },
    },
  };
</script>

<style lang="sass" scoped>
  .sp-delete-confirmation-modal
    .title
      font-weight: 700
      font-size: 18px
      line-height: 24px
    .confirm-btn
      margin-left: 10px
</style>
