<template lang="pug">
.nonstandard-orders-container
  h3.mb-6 {{ $t('analyticsPage.nonstandardOrders') }}
  .table.brand-table
    .thead
    .tbody
      .tr.brand-table-tr(v-for="domain in domains")
        .td.domain-name.mr-3 {{ domain.domain }}
        .td.useNonstandardOrders {{ domain.useNonstandardOrders ? 'GA4 nonstandard orders' : 'standard orders' }}
</template>
<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters(['domains']),
    },
  };
</script>
<style lang="sass" scoped>
  .brand-table .tbody
    .brand-table-tr
      padding: 0 8px
      min-height: 2.5rem

    .domain-name
      text-align: left
</style>
