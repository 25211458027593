import { chatGPTModelVersions } from '@om/workflow-sppo/src/chatgptModelVersions';

export const blank = {
  label: 'Add new prompt',
  displayName: '',
  modelVersion: chatGPTModelVersions.getDefaultVersion(),
  variableNames: [''],
  settings: {
    postProcess: [
      {
        variableName: '',
        removeText: null,
        capitalizeFirstLetter: false,
        periodAtTheEnd: 'dontModify',
        removeApostrophe: false,
      },
    ],
    dataValidation: [
      {
        maxResultLength: null,
        minDescriptionLength: null,
        minResultLength: null,
        variableName: '',
      },
    ],
  },
};
